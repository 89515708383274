<template>
    <div class="newuser">
        <div class="corset">

            <Svgicon icon="logo" />

            <p class="newuser__introtext">Opret en gratis demo konto på vegne af en partner. De modtager en velkomstmail med login.</p>

            <div class="signup">
                <div class="signup__header">Opret partner demo</div>
                <div class="signup__content">
                    <LocalSpinner :active="spinner_active"/>    
                    <div class="field">
                        <label for="company_name">Partner firmanavn</label>
                        <input type="text" ref="company_name" id="company_name" name="company_name" v-model="info.company_name" placeholder="Firmanavn ApS" @input="search_company">
                        <div v-if="company_list" class="company_list">
                            <ul ref="companylist" v-if="!no_companies_found">
                                <li v-for="company in company_list" :key="company._id" @click.prevent="select_company(company)" class="list--item">
                                    <p class="title">{{company._source.Vrvirksomhed.virksomhedMetadata.nyesteNavn.navn}}</p>
                                    <p class="subtitle">CVR: {{company._source.Vrvirksomhed.cvrNummer}}</p>
                                </li>
                            </ul>
                            <ul ref="companylist" v-else>
                                <li class="list--item">
                                    <p class="title">Ingen firmaer fundet.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="field">
                        <label for="email">Partner e-mail</label>
                        <input type="email" id="email" v-model="info.email" name="email" placeholder="mail@mail.dk">
                    </div>

                    <div class="field">
                        <label for="consultant">Konsulent initialer</label>
                        <input type="text" id="consultant" v-model="info.user_consultant" name="consultant" placeholder="ADN">
                    </div>
                    <br>
                    <button class="btn btn--full" @click="create_user">Opret partner</button>

                    
                </div>
                
            </div>

            <transition name="fade">
                <div class="user_link" v-if="link">Partner oprettet med unikt link: {{link}}</div>
            </transition>
            
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import LocalSpinner from '@/components/LocalSpinner';
export default {
    components: {LocalSpinner},
    data() {
        return {
            info: {
                company_name: '',
                company_vat: 0,
                email: '',
                user_consultant: '',
            },
            no_companies_found: false,
            link: null,
            company_list: [],
            currentItem: -1,
            spinner_active: false,
        }
    },

    methods: {
        async create_user() {

            const toast = useToast();

            const that = this
            if( this.info.company_name.length < 2) { 
                toast.error("Mangler firmanavn");
                return false;
            }
            if( this.info.company_vat.toString().length < 8) { 
                toast.error("Har du valgt et firma fra listen?");
                return false;
            }
            if( this.info.email.length < 2) { 
                toast.error('Mangler e-mail'); 
                return false; 
            }
            if( !this.validateEmail(this.info.email) ) {
                toast.error("E-mailen ser ikke helt korrekt ud."); 
                return false; 
            }

            this.spinner_active = true;

            const data = this.info
            data.skipPipedrive = true;
            const resp = await this.$store.dispatch('create_user', data);
            this.spinner_active = false;
            if( resp.status == 'success' ) {

                    toast.success( that.info.company_name + ' oprettet og mail sendt')
                    that.link = 'https://demo.3byggetilbud.dk/#'+ resp.data.user_hash
                    that.info.company_name = ''
                    that.info.email = ''
                    that.info.user_consultant = ''

            } else {
                toast.error(resp.data)
            }

        },

        validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        async search_company(event) {
            this.currentItem = -1;
            let input = event.target.value
            if( input.length < 2 ) {
                this.company_list = []
                return false
            }

            const resp = await this.axios.post( process.env.VUE_APP_APIROOT + '/api/?e=find_cvr', { query: input } )
            if( this.info.company_name.length < 2) { 
                this.company_list = []
                this.no_companies_found = false
                return false;
            }
            this.company_list = resp.data.data.hits.hits

            this.no_companies_found = false
            if(this.company_list.length < 1 && this.info.company_name.length > 1) {
                this.no_companies_found = true
            }
        },

        select_company(company) {
            this.info.company_name = company._source.Vrvirksomhed.virksomhedMetadata.nyesteNavn.navn
            this.info.company_vat = company._source.Vrvirksomhed.cvrNummer
            this.company_list = []
        },

        traverseList(e) {
            
            var items       = this.$refs.companylist.children;
            var list_len    = items.length

            if (e.keyCode == 38 && this.currentItem > 0) {
                this.currentItem--
            } else if (e.keyCode == 40 && this.currentItem < list_len - 1) {
                this.currentItem++
            }

            if( e.keyCode == 38 || e.keyCode == 40) {

                
                var item = items[this.currentItem]
                for (const item of items) {
                    item.classList.remove('highlighted')
                }
                
                item.classList.add('highlighted')
                // Scroll into view if moving into the overflow area
                items[this.currentItem].scrollIntoView({block: "nearest"});
            }
            
            if ( e.keyCode == 13 ) { // Enter
                const curr_company = this.company_list[this.currentItem]
                this.select_company(curr_company)
            }            
        }
    },


    watch: {
        'info.company_name': function (val) {
            if( val.length < 2 ) {
                this.no_companies_found = false;
                this.company_list = []
                this.info.company_vat = 0;
            }
        }
    },


    mounted() {
        this.$refs.company_name.addEventListener('keydown', this.traverseList)            
    },

    destroyed() {
        this.$refs.company_name.removeEventListener('keydown', this.traverseList)
    },

    
}
</script>

<style lang="scss">

.newuser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    margin-top: -100px;
    @include small {
        margin-top: 0px;
    }

    .svgicon {
        width: 130px;
        margin: 0 auto;
        display: block;
        margin-bottom: 2rem;
        color: $color1;
    }

    &__introtext {
        text-align: center;
        max-width: 620px;
        margin: 0 auto;
        margin-bottom: 2rem;
    }

}

.signup {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    box-shadow: $shadow3;
    background: white;
    border: 1px solid #D1D5DB;
    border-radius: $radius;

    &__header {
        background: #F7F8FB;
        border-bottom: 1px solid #D1D5DB;
        padding: 1rem 2rem;
        text-align: center;
        border-radius: $radius $radius 0 0;
        font-size: 1.5rem;
        font-weight: 600;
        color: $color1;
    }

    &__content {
        position: relative;
        padding: 2rem 2rem;
        
    }
}


.user_link {
    text-align: center;
    margin-top: 1rem;
    font-size: 0.9rem;
    font-weight: bold;
}

</style>